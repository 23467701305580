import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSClick } from '../customHooks/sclickHooks';

import Footer from '../components/Footer';

import './Top5.css';
import {UserContext} from "../context/UserContext";
import {useUser} from "../customHooks/userHooks";

function Top5() {
  let navigate = useNavigate();
    const { openRegisterPopup, setCurrentGame } = useContext(UserContext);
    const { showRegisterBtn } = useUser();
    const [contentHeight, setContentHeight] = useState();
    const { showSClickId, sClickIdFrameSrc, } = useSClick();

  useEffect(() => {
    document.title = 'Grandy Winner - Top 5';

    const contentBlock = document.querySelector('.top-page-body');
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

    const top5GamesArr = ['coils-of-cash', 'lady-of-fortune', 'jade-magician', 'the-magic-cauldron', 'street-magic'];

    useEffect(() => {
        // Load Google Tag Manager script
        const loadGoogleTagManager = () => {
            const script = document.createElement('script');
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-K28KJF3');`;
            document.head.appendChild(script);
        };

        loadGoogleTagManager();

        // Clean up function
        return () => {
            // Remove Google Tag Manager script
            const script = document.querySelector('script[src^="https://www.googletagmanager.com/gtm.js?id="]');
            if (script) {
                script.remove();
            }

            window.dataLayer = undefined;
        };
    }, []);


    return (
    <div className="top-page-body top5-page-body">
        {window.innerWidth >= 1100 ? <img width='1440' height='auto' className="top5-games-background" src="/assets/images/blue-bg.webp" alt="background" /> : null}
        <div className="top5-main-container main-container">
            <div className="top5-text-block">
                <h1 className="top5-games-title title">Top 5 Free Online Casino Games</h1>
                <p>It goes without saying that when playing at an online casino you want to enjoy the game as much as possible, but the possibility of winning and excitement is also very important. But which casino game is best played and which game is the most popular? This game selection contains the currently highest rated games released in the last 12 months at Grandy Winner Casino. Try these games and you’ll feel the thrill of playing free social casino games!
                </p>
            </div>
            <div className="top5__games-wrap">
                <div className="top5__games">
                    {top5GamesArr.map((gameName, i) =>
                        <div className="game-block" key={i}>
                            <div className='hover-img'>
                                <button onClick={() => {
                                    if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame(`${gameName}`);
                                    }else{
                                        navigate(`/play/${gameName}/`);
                                    }
                                }}>Play now</button>
                            </div>
                            <img width='180' height='180' src={`/assets/images/top5-games/${gameName}.webp`} className="first-screen-game" alt="banner" />
                        </div>
                    )}
                </div>
            </div>
            <img className="top5-games-image" src="/assets/images/jar.webp" alt="background" />
        </div>
        <Footer />
        {showSClickId ? (
          <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe>
        ) : (null)}
    </div>
  );
}

export default Top5;
