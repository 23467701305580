import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import './ThankYouPopup.css';

function ThankYouPopup() {
  const { closeThanksPopup } = useContext(UserContext);

  return (
    <div className="thank-you-popup" onClick={() => closeThanksPopup()}>
      <div className="thank-you-overlay"></div>
      <div className="thank-you-body">
        <button className="close-popup-btn">
          <img src="/assets/images/close-icon.svg" alt="close button" />
        </button>
        <h2 className='thank-you-body__title'>Thank you <br /> for subscribing. Follow the SMS & email to claim your welcome bonus!</h2>
        {/*<p>Check your email & SMS for your welcome gift!</p>*/}
        {/*<img className="thank-you-bg" src="/assets/images/thankyou.webp" alt="background" />*/}
      </div>
    </div>
  );
}

export default ThankYouPopup;
