import { Link, NavLink } from 'react-router-dom';

import './Footer.css';

function Footer({ className }) {
  return (
    <footer className={`footer ${className ? className : ''}`}>
      {window.innerWidth <= 991 ?
          <Link to="/home/" className='footer-logo-container'>
            <img width='283' height='122' src="/assets/images/footer-logo.webp" className="footer-logo" alt="" />
          </Link> : null}
      <div className="footer-inner-container main-container">
        <div className="footer-left-block">
          {window.innerWidth > 991 ?
              <Link to="/home/" className='footer-logo-container'>
                <img width='283' height='122' src="/assets/images/footer-logo.webp" className="footer-logo" alt="" />
              </Link> : null}
          <ul className="footer-nav">
            <li>
              <NavLink to="/disclaimer/">Disclaimer</NavLink><span>|</span>
            </li>
            <li>
              <NavLink to="/privacy/">Privacy Policy</NavLink><span>|</span>
            </li>
            <li>
              <NavLink to="/terms/">Terms & Conditions</NavLink>
            </li>
          </ul>
        </div>
        <div className="footer-right-block">
          <div className="footer-content">
            <h2 className="footer-cover-title">Disclaimer</h2>
            <p>Grandy Winner offers social casino games aimed at free entertainment only without any monetary prizes. Winning in a social casino doesn’t guarantee your future success in real money games. Due to the nature of our games, we restrict access to our content for visitors below the age of 18 years old. Play responsibly: if you feel you are getting addicted to this activity, we advise you to seek professional assistance.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
