import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Steps from '../components/lp_blocks/Steps';
import TopGames from '../components/lp_blocks/TopGames';
import Guide from '../components/lp_blocks/Guide';
import { useSClick } from '../customHooks/sclickHooks';

import './Home.css';

function Home() {
  const [bodyHeight, setBodyHeight] = useState();
  const { showSClickId, sClickIdFrameSrc } = useSClick();

  useEffect(() => {
    document.title = 'Grandy Winner';
  }, []);

  return (
    <div className="home-page-cover lp-home-page-cover">
      <Steps />
      <TopGames />
      <Guide />
      <Footer />

        {showSClickId ? (
          <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe>
        ) : (null)}
    </div>
  );
}

export default Home;
