import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useUser } from '../../customHooks/userHooks';
import { gamesUrlList } from '../../data/gamesUrlList';


import './TopGames.css';

function TopGames() {
  let navigate = useNavigate();
  const { openRegisterPopup, setCurrentGame, getRandomGame } = useContext(UserContext);
    const { showRegisterBtn } = useUser();

    const topGamesArr = ['big-win-cat', 'fire-joker', 'dragon-maiden', '5x-magic', 'wild-falls', 'coils-of-cash'];



  return (
    <>
      <div className="top-games-block">
          <div className="main-container">
              <div className="top-games-wrap">
                <h2 className="title">Our Top Games and Slots</h2>
                <p className='banner-text'>Here are the best games of Grandy Winner Casino. This list is the result of the choice of our visitors. Check it out and choose a game to play!
                </p>
                <div className="top-games">
                    {topGamesArr.map((gameName, i) =>
                        <div className="game-block" key={i}>
                            <div className='hover-img'>
                                <button onClick={() => {
                                    if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame(`${gameName}`);
                                    }else{
                                        navigate(`/play/${gameName}/`);
                                    }
                                }}>Play now</button>
                            </div>
                            <img width='182' height='182' src={`/assets/images/home_games/${gameName}.webp`} className="first-screen-game" alt="banner" />
                        </div>
                    )}
                </div>
                  <button className='banner-btn main-btn' onClick={() => {
                      if(showRegisterBtn){
                          openRegisterPopup(false);
                          setCurrentGame('');
                      }else{
                          navigate(`/play/${getRandomGame(gamesUrlList)}/`);
                      }
                  }}>Start Now</button>
              </div>
          </div>
      </div>
    </>
  );
}

export default TopGames;
