import React, {useState, useContext, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import {UserContext} from "../../context/UserContext";

function Terms() {
    const [page, setPage] = useState('/');
    const [fullPage, setFullPage] = useState (true);
    const { setShowAgepopup, setShowCookiePopup} = useContext(UserContext);

    useEffect(() => {
        document.title = `Grandy Winner - Terms and Conditions`;
        setPage(localStorage.getItem('page'));

        if(localStorage.getItem('termsPage') === 'notFull'){
            setFullPage(false);
            setShowCookiePopup(false);
            setShowAgepopup(false);
        }else if(localStorage.getItem('termsPage') === null){
            setFullPage(true);
        }
    }, [ fullPage, setFullPage]);
    const removeKey = () =>{
        localStorage.removeItem('termsPage');
    }

    return (
        <>
            <div className="main-container">
                <div className="content-cover">
                    <h1 className='legal-title'>Terms and Conditions</h1>
                    <p>We warmly welcome you to Grandy Winner Casino, offering free-to-play games. We always look for excellent use of all our services, so it is necessary to establish some guidelines.</p>
                    <p>The basic requirements for your participation or access to our website are:</p>
                    <p>Accept these Terms and Conditions;</p>
                    <p>Be 18 years of age or older. (Access +18 )</p>
                    <p>Therefore, continuing to participate and access this website is considered a declaration of majority and also of knowledge, complete understanding and acceptance of the following terms and conditions.</p>
                    <p>1. Terms</p>
                    <p>Grandy Winner Casino offers exclusive free-to-play games and slots, doesn't offer monetary prizes, or receives any charges from its users. All the content is created for entertainment only.</p>
                    <p>The user is responsible for complying with all laws and regulations in the region of access, complying with all applicable legal requirements.</p>
                    <p>The user may not use this site for purposes other than those determined herein and indicated by the company, whether commercial or non-commercial, except prior, express and written authorization.</p>
                    <p>2. License</p>
                    <p>The use of the company brand as a logo, name, brand or any other means of identification of our website is expressly prohibited, as is the use of images, frames of this website or hyperlink, except in cases and forms already authorized in these Terms and Conditions or with prior authorization.</p>
                    <p>The content of this website may only be reproduced for the personal use of the individual user. Changes, copies, republications or commercialization of the content available on this site are not allowed without the prior and express authorization of the company.</p>
                    <p>We reserve the right to pause or cancel the website at our discretion, with or without prior notice, without the consent of the users.</p>
                    <p>3. User registration</p>
                    <p>In order to stay close to us, receive our newsletter about our updates, the user must register with our site. The Casino will keep the personal information submitted by you secure and following our Privacy Policy.</p>
                    <p>4. Third-Party Links</p>
                    <p>Grandy Winner Casino may contain links referring to third-party websites or services not owned or controlled by us. They are not our property, and we are not responsible for any third-party websites or services' content, privacy policies, or practices. You will use these links, you are doing it at your own risk. Please read the terms and policies of any third-party websites or services before using them.</p>
                    <p>5. Responsible Gaming</p>
                    <p>We are committed to responsible gaming and encourage our visitors to play responsibly and pay attention to your state. If you feel addicted to this activity, please consult the specialist.</p>
                    <p>6. Changes to these Terms</p>
                    <p>We may update these Terms occasionally by posting a new version on our website. Your continued use of our website or services after such changes constitutes your acceptance of the revised Terms.
                    </p>
                    { fullPage ?
                        '' :
                        <Link className='goback-link main-btn' to={page}><span onClick={removeKey}>Go Back</span></Link>
                    }
                </div>
            </div>

            { fullPage ?
                <Footer /> :
                null
            }
        </>
    );
}

export default Terms;